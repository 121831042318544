import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import './util/rem'

// 导入全局样式表
import "./assets/css/global.css";

Vue.config.productionTip = false;

new Vue({
  el: "#app",
  router,
  render: (h) => h(App),
});
